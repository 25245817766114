import {Injectable, Injector} from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {LoaderService} from '../components/loader/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const started = Date.now();
    const isContinue = req.url.indexOf('continueLoader=true') > -1 ? true : false;
    const loaderService = this.injector.get(LoaderService);
if (!req.url.includes('refresh-access-token')){
    loaderService.show();
}
if (req.url.includes('portal-config/get-config')
  || req.url.includes('refresh-access-token')
  || req.url.includes('customer-branch/config')
  || req.url.includes('notification/user')
  || req.url.includes('user-notification/update-token')
  || req.url.includes('assets/i18n/ar.json')
  || req.url.includes('/assets/images/item.png')
  || req.url.includes('assets/i18n/en.json')
  || req.url.includes('api/v1/branch-items/pos')
  || req.url.includes('api/v1/category/findByBranch')
  || req.url.includes('api/v1/customer-branch/delivery-methods')
  || req.url.includes('api/v1/issuing-type/page')
  || req.url.includes('api/v1/walking-consumer/nameAndPhone')
){
  loaderService.hide();
}
    return next.handle(req).pipe(
      catchError((error :HttpErrorResponse) => {
        loaderService.hide();
        return throwError(error);
      }),
      finalize(() => {
        if (isContinue) {
          return;
        }
        const elapsed = Date.now() - started;
        /* check time taken by request to delay in case of short time to show spinner  */
        if (elapsed < 200) {
            setTimeout(() => {
                loaderService.hide();
            }, 300);
        } else {
            loaderService.hide();
        }
        })
    );
  }


}
